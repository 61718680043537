export default {
  title: "Essais",
  subtitle: "Attempts to understand",
  author: {
    firstname: "Ramin",
    lastname: "Azhdari",
  },
  about: "<strong>Essais</strong> is a personal project to organize my thoughts and store what I learn during my work as a Software Engineer. Hopefully it will be useful to you too.",
  pages: {
    about: {
      name: "About",
      title: "Hello everyone! 👋",
      introduction: ["My name is Ramin and I'm a German software engineer living in Paris, France. I currently work at Alan, where I help to improve accessing health care services. I currently work mostly with Python (Flask) and TypeScript (React).", "Before I started coding I studied medicine and became a doctor. When I'm not coding I spend most of my time reading (non fiction and classics)."],
    },
    posts: {
      name: "Posts",
    },
  }
}
