import styled  from 'styled-components'
import * as t from "../../theme"
import { H2, H3 } from './HTMLElement'

export const NavTitle = styled(H3)`
  font-size: 2em;
  font-family: ${t.fontFamily.body};
  color: ${({ theme }) => theme.heading};
  ${t.addMarginProps}
  ${t.themeTransition}
`

export const StyledH2 = styled(H2)`
  color: ${ ({ theme }) => theme.heading};
  font-weight: ${ t.fontWeight.heading};
  font-size: ${ t.fontSize.h2};
  font-family: ${ t.fontFamily.heading};
  line-height: ${ t.lineHeight.heading};
  ${t.addMarginProps}
  ${t.themeTransition}
`

export const PostTitle = styled.h4`
  margin: 0;
  font-size: 1.4em;
  font-family: ${t.fontFamily.heading};
  font-weight: ${t.fontWeight.title};
  margin-bottom: ${props => props.my || props.mb || 0};
  margin-top: ${props => props.my || props.mt || 0};
  ${t.themeTransition}
`
