import React, { useEffect, useRef, useState, useContext } from "react"

import NavBar from "./Nav"
import { ThemeProvider } from "styled-components"
import { ThemeContext } from "./stores/theme"
import { lightTheme, darkTheme } from "../theme/color"
import { GlobalStyle } from "../theme"
import { Box } from "rebass"
import { StyledDiv } from "./styles"

const Layout = ({ children }) => {
  const [theme, setTheme] = useContext(ThemeContext)
  const [isScrolled, setScroll] = useState(false)
  const [isPreload, setPreload] = useState(true)
  const PRELOAD_TRANSITIONS = "preload-transitions"
  const ref = useRef(null)

  const toggleTheme = () =>
    setTheme(theme.vscode === "light" ? darkTheme : lightTheme)

  const handleScroll = () => {
    // on every new render ref is null
    if (!ref.current || ref.current.getBoundingClientRect().top < 0) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  const handleFinishedLoad = () => setPreload(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    handleFinishedLoad()

    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <ThemeProvider theme={theme.vscode === "light" ? lightTheme : darkTheme}>
      <GlobalStyle />
      <Box
        minHeight={"100vh"}
        className={isPreload ? PRELOAD_TRANSITIONS : undefined}
      >
        <StyledDiv ref={ref} data-theme={theme.vscode}>
          <NavBar
            scrolled={isScrolled ? true : false}
            toggleTheme={toggleTheme}
          />
          <Box minWidth="300px" maxWidth="850px" mx="auto">
            {children}
          </Box>
        </StyledDiv>
      </Box>
    </ThemeProvider>
  )
}

export default Layout
