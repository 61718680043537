import React, { useContext } from 'react'
import { Flex, Box } from "rebass"
import { ThemeContext } from 'styled-components'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'

import content from "../../content/i18n/en"
import { Link, NavTitle, BlogH4 } from "./styles"
import { Nav, StyledFontAwesomeIcon } from "./styles/Wrapper"
import { gridUnit } from '../theme'

const NavBar = ({ scrolled, toggleTheme }) => {
  const theme = useContext(ThemeContext)

  return (
    <Nav
      scrolled={scrolled}
    >
      <Flex
        px={gridUnit(3)}
        py={2}
        mx='auto'
        alignItems='center'
        justifyContent='space-between'
        maxWidth='800px'
      >
        <NavTitle>
          <Link to={`/`}>
            {content.title}
          </Link>
        </NavTitle>
        <Flex alignItems='center'>
          <Box>
            <Link to={'/'}>
              <BlogH4>
                {content.pages.posts.name}
              </BlogH4>
            </Link>
          </Box>
          <Box pl={3}>
            <Link to={'/about'}>
              <BlogH4>
                {content.pages.about.name}
              </BlogH4>
            </Link>
          </Box>
          <Box pl={3}>
            <StyledFontAwesomeIcon
              icon={theme.vscode === 'light' ? faMoon : faSun}
              onClick={toggleTheme}
            />
          </Box>
        </Flex>
      </Flex>
    </Nav>
  )
}

export default NavBar
