import styled from 'styled-components'
import * as t from '../../theme'
import { P } from './HTMLElement'

export const StyledP = styled(P)`
  font-weight: ${ t.fontWeight.body};
  font-size: ${ t.fontSize.body};
  font-family: ${ t.fontFamily.body};
  line-height: ${ t.lineHeight.body};
  color: ${({ theme }) => theme.body};
  text-align: justify;
  text-justify: inter-character;
  hyphens: auto;
  
  max-width: 600px;

  ${t.themeTransition}

  @media (max-width: ${t.breakPoint.mobile}) {
    text-align: left;
    hyphens: none;
  }
`