import styled from 'styled-components'

export const P = styled.p`
  margin: 0;
`

export const A = styled.a`
  text-decoration: none;
`

export const Ul = styled.ul`
  list-style: none;
  margin: 0;
`

export const H1 = styled.h1`
  margin: 0;
`

export const H2 = styled.h2`
  margin: 0;
`

export const H3 = styled.h3`
  margin: 0;
`

export const H4 = styled.h4`
  margin: 0;
`

export const Div = styled.div`
  margin: 0;
`