import { createGlobalStyle } from "styled-components"
import { fontFamily } from "./font"
import { themeTransition } from "./transition"
import { breakPoint } from "./breakPoint"

export const GlobalStyle = createGlobalStyle`
  :root {
    --link-color: ${({ theme }) => theme.link};
    --blue: ${({ theme }) => theme.blue};
  }

  html, body {
    min-height: 100%;
    margin: 0;
  }

  #___gatsby, #gatsby-focus-wrapper {
    min-height: 100vh;
  }

  html {
    @media (max-width: ${breakPoint.mobile}) {
      font-size: 16px;
    }
    @media (min-width: ${breakPoint.mobile} and max-width: ${
  breakPoint.tablet
}) {
      font-size: 17px;
    }
    @media (min-width: ${breakPoint.tablet}) {
      font-size: 18px;
    }
  }

  body {
    background-color: ${({ theme }) => theme.background};
    text-size-adjust: none;
    font-kerning: auto;
    padding: 0px;
    margin: 0px;
    font-family: ${fontFamily.body};

    [data-example] {
      color: ${({ theme }) => theme.example};
    }

    /* ${themeTransition} */
  }
  .preload-transitions * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
`
