export const fontFamily = {
  heading: 'Montserrat, sans-serif',
  body: '"Roboto Slab", serif',
  code: '"Source Code Pro",  source-code-pro, Menlo, Monaco, Courier New, monospace',
}

export const fontSize = {
  h1: '1.8rem',
  h2: '1.4rem',
  h3: '1.2rem',
  body: '1rem',
  code: '0.85rem',
}

export const fontWeight = {
  heading: 800,
  body: 400,
  code: 500,
}

export const lineHeight = {
  heading: 1.2,
  body: 1.75,
  code: 1.75,
}