import styled, { css } from "styled-components"
import * as t from "../../theme"
import { H1, H3, H4, A } from "./HTMLElement"
import { StyledH2 } from "./Heading"
import { StyledP } from "./Paragraph"

const HIGHLIGHT_BORDER_WIDTH = "0.2rem"

const headingMargin = css`
  margin: ${t.gridUnit(4)} 0 ${t.gridUnit()} 0;
`

const textGridDefaults = css`
  grid-column: 2;
  justify-self: center;
  width: 100%;
  max-width: 600px;
`

export const BlogH1 = styled(H1)`
  color: ${({ theme }) => theme.heading};
  font-weight: ${t.fontWeight.heading};
  font-size: ${t.fontSize.h1};
  font-family: ${t.fontFamily.heading};
  line-height: ${t.lineHeight.heading};
  ${headingMargin}
  ${textGridDefaults}
  ${t.themeTransition}
`

export const BlogH2 = styled(StyledH2)`
  ${headingMargin}
  ${textGridDefaults}
`

export const BlogH3 = styled(H3)`
  color: ${({ theme }) => theme.heading};
  font-weight: ${t.fontWeight.heading};
  font-size: ${t.fontSize.h3};
  font-family: ${t.fontFamily.heading};
  line-height: ${t.lineHeight.heading};
  ${headingMargin}
  ${textGridDefaults}
  ${t.themeTransition}
`

export const BlogH4 = styled(H4)`
  color: ${({ theme }) => theme.heading};
  font-weight: ${t.fontWeight.heading};
  font-size: ${t.fontSize.body};
  font-family: ${t.fontFamily.heading};
  line-height: ${t.lineHeight.body};
  ${textGridDefaults}
  ${t.themeTransition}
`

export const BlogP = styled(StyledP)`
  margin: ${t.gridUnit()} 0;
  ${textGridDefaults}
  ${t.themeTransition}
`
/* TODO: Fix the styling (adapt to code block's styling and CssExample) */
export const BlogBlockquote = styled.blockquote`
  font-style: italic;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(0, 10ch) minmax(auto, 60ch) minmax(0, 10ch);
  grid-column: 1 / 4;
  justify-content: center;
  background-color: ${({ theme }) => theme.card};
  border-radius: ${t.gridUnit()};
  padding-top: ${t.gridUnit(2.5)};
  padding-bottom: ${t.gridUnit(2.5)};
  margin: ${t.gridUnit(2.5)} 0;
  ${t.smallBoxShadow}
  ${t.themeTransition}

  @media (max-width: ${t.breakPoint.tablet}) {
    margin: ${t.gridUnit()} 0;
    grid-template-columns: ${t.gridUnit(3)} minmax(0, auto) ${t.gridUnit(3)};
    width: 100%;
    border-radius: 0;
  }
`

export const BlogUl = styled.ul`
  list-style-type: square;
  padding-left: ${t.gridUnit(3)};
  margin: ${t.gridUnit()} 0;
  ${textGridDefaults}
  ${t.themeTransition}
`

export const BlogOl = styled.ol`
  margin: ${t.gridUnit()} 0;
  ${textGridDefaults}
  ${t.themeTransition}
`

export const BlogLi = styled.li`
  padding-left: ${t.gridUnit()};
  font-weight: ${t.fontWeight.body};
  font-family: ${t.fontFamily.body};
  font-size: ${t.fontSize.body};
  line-height: ${t.lineHeight.body};
  color: ${({ theme }) => theme.body};
  ${t.themeTransition}

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  /* CSS wildcard to display P in footnotes inline */
  &[id^="fn-"] p {
    display: inline;
  }
`

export const BlogHr = styled.hr`
  grid-column: 1 / 4;
  height: 0;
  border-width: 0 0 2px 0;
  border-image: linear-gradient(
      90deg,
      ${({ theme }) => theme.transparent} 10%,
      ${({ theme }) => theme.border} 50%,
      ${({ theme }) => theme.transparent} 90%
    )
    1;
  margin: ${t.gridUnit(7)} 0 ${t.gridUnit(6)} 0;
  ${t.themeTransition}
`

export const BlogPre = styled.pre`
  grid-column: 1 / 4;
  margin-top: ${t.gridUnit(2.5)};
  margin-bottom: ${t.gridUnit(2.5)};

  &&& {
    background-color: ${({ theme }) => theme.card};
    border-radius: ${t.gridUnit()};
    padding-top: ${t.gridUnit(2.5)};
    padding-bottom: ${t.gridUnit(2.5)};
    box-sizing: border-box;
  }
  ${t.smallBoxShadow}
  ${t.themeTransition}

  /* TODO: Get rid of this important */
  @media (max-width: ${t.breakPoint.tablet}) {
    border-radius: 0 !important;
  }
`

export const BlogCode = styled.code`
  grid-column: 2;
  font-family: ${t.fontFamily.code};
  font-weight: ${t.fontWeight.code};
  line-height: ${t.lineHeight.code};
  color: ${({ theme }) => theme.body};
  background-color: ${({ theme }) => theme.inlineCodeBackground};
  border-radius: ${t.gridUnit(0.5)};
  padding: 0.1rem 0.3rem;

  /* Don't hyphenate code */
  hyphens: none;

  /* unset variables for code usage in codeblock */
  &.grvsc-code {
    font-size: ${t.fontSize.code};
    padding: unset;
    border-radius: unset;
    background-color: unset;
    color: unset;
  }
  ${t.themeTransition}
`

export const BlogCodeSpan = styled.span`
  &.grvsc-line {
    padding-left: ${t.gridUnit(5)};
    padding-right: ${t.gridUnit(5)};

    @media (max-width: ${t.breakPoint.tablet}) {
      padding-left: ${t.gridUnit(3)};
      padding-right: ${t.gridUnit(3)};
    }
  }

  /* line highlighting */
  &.grvsc-line.grvsc-line-highlighted {
    background-color: ${({ theme }) => theme.codeHighlight};
    padding-left: calc(${t.gridUnit(5)} - ${HIGHLIGHT_BORDER_WIDTH});
    border-left: ${HIGHLIGHT_BORDER_WIDTH} solid
      ${({ theme }) => theme.highlight};

    @media (max-width: ${t.breakPoint.tablet}) {
      padding-left: calc(${t.gridUnit(3)} - ${HIGHLIGHT_BORDER_WIDTH});
    }
  }

  ${t.themeTransition}
`

export const BlogImg = styled.img`
  display: block;
  max-height: 500px;
  max-width: calc(100% - ${t.gridUnit(8)});
  border-radius: ${t.gridUnit()};
  margin: ${t.gridUnit(4)} auto;
  ${t.smallBoxShadow}

  @media (max-width: ${t.breakPoint.mobile}) {
    max-width: 100%;
  }
`

export const BlogA = styled(A)`
  grid-column: 2;
  color: ${({ theme }) => theme.body};
  background-image: linear-gradient(
                      ${({ theme }) => theme.link},
                      ${({ theme }) => theme.link});
  background-repeat: no-repeat;
  background-size: calc(100% - ${t.gridUnit()}) 0.1em;
  background-position: bottom;
  padding: 2px ${t.gridUnit(0.5)};
  margin: -2px -${t.gridUnit(0.5)};

  hyphenate: auto;
  justify-content: inter-character;
  ${t.themeTransition}

  &:hover {
    padding: 2px 3px;
    margin: -2px -3px;
    background-color: ${({ theme }) => theme.link};
    background-image: none;
    border-radius: ${t.gridUnit(0.5)};
  }

/* doesn't work with background images */
/*  &:visited {
    background-image: linear-gradient(
                      120deg,
                      ${({ theme }) => theme.visitedLink} 0%,
                      ${({ theme }) => theme.visitedLink} 100%);
    background-repeat: no-repeat;
    background-size: 99% 0.1em;
    background-position: bottom;
    &:hover {
      background-color: ${({ theme }) => theme.visitedLink};
      background-image: none;
    } */
  }

  &.footnote-backref {
    padding: 0 0.2rem;
    margin-left: ${t.gridUnit(0.5)};
    background-color:  ${({ theme }) => theme.card};
    background-image: none;
    border-radius: ${t.gridUnit()};
    text-decoration: none;

    &:before {
      display: none;
    }
  }
`

export const BlogSup = styled.sup`
  padding-left: 0.2rem;
  padding-right: 0.2rem;

  /*
  This way the nav does not cover the anchor.
  If only Chrome support would be needed we could use scroll-padding-top.
  */
  padding-top: 70px;
  margin-top: -70px;

  a {
    background-image: none;

    &:before {
      display: none;
    }

    &:hover {
      background-color: ${({ theme }) => theme.transparent};
    }
  }
`

/* TODO: There are a lot of duplications between codeblocks this and blockquotes */
export const CssExample = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(0, 10ch) minmax(auto, 60ch) minmax(0, 10ch);
  grid-column: 1 / 4;
  justify-content: center;
  background-color: ${({ theme }) => theme.card};
  border-radius: ${t.gridUnit()};
  padding-top: ${t.gridUnit(2.5)};
  padding-bottom: ${t.gridUnit(2.5)};
  margin-top: ${t.gridUnit(2.5)};
  margin-bottom: ${t.gridUnit(2.5)};
  overflow: scroll;
  ${t.smallBoxShadow}
  ${t.themeTransition}

  /* TODO: Can this be calculated? */
  > h1:first-child, h2:first-child, h3:first-child, h4:first-child {
    margin-top: ${t.gridUnit(2)};
  }

  @media (max-width: ${t.breakPoint.tablet}) {
    grid-template-columns: ${t.gridUnit(3)} minmax(0, auto) ${t.gridUnit(3)};
    width: 100%;
    border-radius: 0;
  }
`
