import styled from 'styled-components'
import * as t from '../../theme'

export const Button = styled.div`
  display: flex;
  justify-content: center;
  padding: ${t.gridUnit(1)};
  width: auto;
  background-color: ${({ theme }) => theme.card};
  border-radius: ${t.gridUnit()};

  &:hover {
    background-color:  ${({ theme }) => theme.cardHover};
  }
  ${t.smallBoxShadow}
  ${t.themeTransition}
  transition: background-color 0.2s linear;
`
