import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as t from "../../theme"

export const Nav = styled.nav`
  background-color: ${({ theme }) => theme.background};
  z-index: 100;
  position: sticky;
  top: 0px;
  overflow: hidden;
  border-image: linear-gradient(
      90deg,
      ${({ theme }) => theme.transparent} 10%,
      ${({ theme }) => theme.border} 50%,
      ${({ theme }) => theme.transparent} 90%
    )
    1;
  border-width: 0 0 2px 0;
  border-bottom-style: solid;
  ${t.themeTransition}

  ${({ scrolled }) =>
    scrolled &&
    css`
      border-width: 0;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
        0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
        0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    `};
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.yellow};
  font-size: 1.2em;
  ${t.themeTransition}
`

export const ArticleGrid = styled.article`
  /* this way we don't need to write the same thing twice */
  &&,
  .footnotes {
    display: grid;
    grid-template-columns: minmax(0, 10ch) minmax(auto, 60ch) minmax(0, 10ch);

    /*
    since Gatsby injects the footnotes div we need to recreate the grid inside
    of it. Basically same grid twice.
    */
    grid-column: 1 / 4;

    @media (max-width: ${t.breakPoint.tablet}) {
      box-sizing: border-box;
      grid-template-columns: ${t.gridUnit(3)} minmax(0, auto) ${t.gridUnit(3)};
      width: 100%;
    }
  }
`

export const StyledDiv = styled.div`
  background-color: ${({ theme }) => theme.background};
  min-height: 100vh;
  ${t.themeTransition}
`
