import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import * as t from '../../theme'

export const Link = styled(GatsbyLink)`
  box-shadow: none;
  text-decoration: none;
  color: inherit;
`

export const Pointer = styled(GatsbyLink)`
  box-shadow: none;
  text-decoration: none;
  color: inherit;


  margin: 0;
  font-size: ${t.fontSize.body};
  font-family: ${t.fontFamily.heading};
  font-weight: 600;
  color: ${({ theme }) => theme.body};
  text-transform: none;
  ${t.themeTransition}
`
